<div nz-row nzJustify="left" nzAlign="middle" class="radio-comp app-icons">
  <audio 
    id="audio" 
    (loadstart)="loadStart()"
    (loadeddata)="loadedData()"
    (error)="loadError($event)"
    preload="all"
    #audioElem>
  </audio>

  <div 
    *ngFor="let data of channelList;index as i"
    nz-col 
    [nzSpan]="detailIndex === i ? 24 : 3"
  >
    <nz-card
      *ngIf="detailIndex === i"
      style="margin:4px;" 
      class="channelDetail"
    >
      <div *ngIf="data" nz-row nzJustify="left" nzAlign="middle" class="app-icons">
        <div nz-col [nzSpan]="10">
          <div style="margin:14px;">
            <img 
            c-img-src 
            dsrc="assets/no-channel-logo.png"
            [rsrc]="data.resources | channelThumb" 
            style="max-width:100%;text-align:center;" 
            />
          </div>
        </div>
        <div nz-col [nzSpan]="14">
          <div class="video-container" #audioContainer>
            <h2 style="font-weight:bolder">
              {{channelList[detailIndex].name}}
            </h2>
            <div *ngIf="isLoading;else navButton">
              <i nz-icon nzType="loading" nzTheme="outline" style="font-size:4em;"></i>
            </div>
            <ng-template #navButton>
              <button nz-button nzType="primary" nzShape="circle" nzSize="large"
                      (click)="playAudio()">
                <i nz-icon nzType="play-circle" nzTheme="outline" ></i>
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button nz-button nzType="secondary" nzShape="circle" nzSize="large"
                     (click)="pauseAudio()">
                <i nz-icon nzType="pause-circle" nzTheme="outline"></i>
              </button>
            </ng-template>
          </div>
        </div>
      </div>
      <i 
        nz-icon 
        nzType="close" 
        nzTheme="outline" 
        style="position:absolute; right:16px; top:16px;font-size:1.5em;cursor: pointer;"
        (click)="closeChannel()"
      >
      </i>
    </nz-card>
    
    <nz-card 
      nzHoverable
      style="margin:4px;" 
      class="channel"
      (click)="showChannel(i)"
      *ngIf="detailIndex !== i"
      nzSize="default"
    >
      <div *ngIf="data" style="position:relative">
        <img 
          c-img-src 
          dsrc="assets/no-channel-logo.png"
          [rsrc]="data.resources | channelThumb" 
          style="max-width:100%;text-align:center;" 
        />
        <h4 style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
          {{data.name}}
        </h4>
      </div>
    </nz-card>
  </div>
</div>
