<div nz-row nzJustify="center">
  <div nz-col nzSpan="6">
    <nz-card>
      <h1> Login </h1>
      <form 
        nz-form 
        [formGroup]="validateForm" 
        class="login-form" 
        (ngSubmit)="submitForm()">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your username!">
            <nz-input-group nzPrefixIcon="user">
              <input 
                nz-input 
                type="text" 
                formControlName="userName" 
                placeholder="Username" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group nzPrefixIcon="lock">
              <input 
                nz-input 
                type="password" 
                formControlName="password" 
                placeholder="Password" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <!-- 
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="12">
            <a class="login-form-forgot">
              Forgot password
            </a>
          </div>
        </div> 
        -->
        <button 
          nz-button
          class="login-form-button login-form-margin" 
          [nzType]="'primary'"
        >Log in</button>
      </form>
    </nz-card>
    <nz-alert
        *ngIf="errorMessage.text"
        nzType="error"
        nzMessage="Error"
        nzDescription="{{errorMessage.text}}"
        nzShowIcon
      ></nz-alert>
  </div>
</div>